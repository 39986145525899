<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <Search
      codeKey="search_type_svr"
      :searchObj="searchType.obj"
      :useKeywordType="true"
      @change-search-type="$_changeSearchType"
      @onSearchClick="$_searchData($event, 'search_type_svr')"
    >
    </Search>

    <esp-dx-data-grid :data-grid="dataGrid" ref="svrGrid" @init-new-row="onInitNewRow" />
  </div>
</template>

<script>
  import Search from '@/components/common/search.vue';
  import { DxSwitch } from 'devextreme-vue/switch';
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      Search,
    },
    data() {
      return {
        cmList: [],
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            pageData: {
              appUrl: {},
            },
          },
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {},
          paramsData: null,
        },
        dataGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'svrGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 350px)', // 주석처리시 100%
          apiActionNm: {
            update: 'EUC_SVR_UPDATE',
            delete: 'EUC_SVR_DELETE',
            loading: true,
          },
          customEvent: {
            initNewRow: true,
          },
          showActionButtons: {
            delete: true,
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: true,
            sorting: true,
            grouping: true,
            paging: true,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '서버명',
              dataField: 'svrNm',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '설명',
              dataField: 'description',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '서버 번호',
              dataField: 'svrInx',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '서버 IP',
              dataField: 'svrIp',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: 'efgw 포트',
              dataField: 'efgwPort',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              // requiredRule: {
              //   message: '필수 항목입니다.',
              // },
            },
            {
              caption: 'adim 포트',
              dataField: 'adimPort',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              // requiredRule: {
              //   message: '필수 항목입니다.',
              // },
            },
            {
              caption: 'ucgw 포트',
              dataField: 'ucgwPort',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: 'cepm 포트',
              dataField: 'cepmPort',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '순서',
              dataField: 'svrOrd',
              width: 100,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '사용 여부',
              dataField: 'viewFl',
              width: 120,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const switchBtn = new DxSwitch({
                  propsData: {
                    // value: options.value === this.$_getUsedCode.id,
                    value: options.value === 'Y',
                    onValueChanged: () => {
                      this.onChangedviewFl(options.data);
                    },
                  },
                });
                switchBtn.$mount();
                container.append(switchBtn.$el);
              },
            },
            {
              caption: '등록일',
              dataField: 'regDt',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: this.formatDt,
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      async onChangedviewFl(data) {
        let viewFl;
        if (data.viewFl === 'Y') {
          viewFl = 'N'; //미사용으로 스위치
        } else {
          viewFl = 'Y'; //사용으로 스위치
        }
        if (data.id) {
          const payload = {
            actionname: 'EUC_SVR_UPDATE',
            data: [
              {
                id: data.id,
                viewFl,
              },
            ],
            loading: true,
          };

          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_msgContents('CMN_SUC_UPDATE'));
            this.$refs.svrGrid.getGridInstance.refresh();
          } else {
            this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          }
        } else {
          data.viewFl = viewFl;
        }
      },
      async selectCmList() {
        const params = {
          // viewFl: this.$_getUsedCode.id,
          viewFl: 'Y',
          sort: '+cmOrd',
        };
        const payload = {
          actionname: 'EUC_CM_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          this.cmList = res.data.data;
        }
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList(sort = '+svrOrd') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            if (this.searchType.paramsData) {
              if (Object.prototype.hasOwnProperty.call(this.searchType.paramsData, 'siteId')) {
                if (!this.searchType.paramsData?.siteId) {
                  delete this.searchType.paramsData.siteId;
                }
              }
            }

            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionname: 'EUC_SVR_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        // cisco 단말동기화 사용안함
        // this.dataGrid.showActionButtons.customButtons = this.getCustomButtons();
        this.$_setPageSettingConfig();
      },
      getCustomButtons() {
        const buttons = [];

        const useSnmp = this.$_getSystemData('use_device_snmp')?.configValue === 'true';
        if (useSnmp) {
          buttons.push({
            widget: 'dxButton',
            options: {
              icon: '',
              text: '단말 SNMP',
              elementAttr: { class: 'btn_XS default filled ' },
              width: 90,
              height: 30,
              onClick: async () => {
                const selectedRowsData = this.$refs.svrGrid.selectedRowsData;
                if (!selectedRowsData?.length) {
                  this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
                  return;
                } else if (selectedRowsData?.length > 1) {
                  this.$_Msg(this.$_msgContents('CMN_ONE_SELECT'));
                  return;
                }
                const appUrl = this.config.pageSetting.config?.appUrl?.deviceSnmp;
                const adimPort = this.$_getSystemData('adim_port')?.configValue;
                await this.onCallBulkWorkEucApi(appUrl, `http://${selectedRowsData[0].svrIp}:${adimPort}`);
              },
            },
            location: 'before',
          });
        }
        buttons.push({
          widget: 'dxButton',
          options: {
            icon: '',
            text: '단말 동기화',
            elementAttr: { class: 'btn_XS default filled ' },
            width: 90,
            height: 30,
            onClick: async () => {
              const selectedRowsData = this.$refs.svrGrid.selectedRowsData;
              if (!selectedRowsData?.length) {
                this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
                return;
              } else if (selectedRowsData?.length > 1) {
                this.$_Msg(this.$_msgContents('CMN_ONE_SELECT'));
                return;
              }
              const appUrl = this.config.pageSetting.config?.appUrl?.deviceSync;
              const adimPort = this.$_getSystemData('adim_port')?.configValue;
              await this.onCallBulkWorkEucApi(appUrl, `http://${selectedRowsData[0].svrIp}:${adimPort}`);
            },
          },
          location: 'before',
        });

        const isVDNSync = this.$_getSystemData('use_vdn_sync').configValue === 'true';
        if (isVDNSync) {
          buttons.push({
            widget: 'dxButton',
            options: {
              icon: '',
              text: 'VDN 동기화',
              elementAttr: { class: 'btn_XS default filled' },
              width: 90,
              height: 30,
              onClick: async () => {
                const selectedRowsData = this.$refs.svrGrid.selectedRowsData;
                if (!selectedRowsData?.length) {
                  this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
                  return;
                } else if (selectedRowsData?.length > 1) {
                  this.$_Msg(this.$_msgContents('CMN_ONE_SELECT'));
                  return;
                }
                const appUrl = this.config.pageSetting.config?.appUrl?.vdnSync;
                const avdnPort = this.$_getSystemData('avdn_port')?.configValue;
                await this.onCallBulkWorkEucApi(appUrl, `http://${selectedRowsData[0].svrIp}:${avdnPort}`);
              },
            },
            location: 'before',
          });
        }
        return buttons;
      },

      async onCallBulkWorkEucApi(appUrl, url) {
        if (!appUrl) {
          this.$_Toast('pageData의 appUrl을 확인해주세요.');
          return;
        }
        const payload = {
          actionname: 'BULKWORK_API_CALL',
          data: {
            appUrl: appUrl,
            reqUser: this.$store.getters.getLoginId,
            url: url,
          },
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_msgContents('CMN_SUCCESS'));
        } else if (res.status === 200 && res.data.header.resCode === 'processing') {
          // 진행중
          this.$_Msg('해당 작업이 진행중입니다.');
        } else {
          this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountData() {
        this.searchType.paramsData = null;
        await this.selectCmList();
        await this.selectDataList();
      },
      /** @description : 데이터 추가 시 기본 값 적용하기 위함 */
      onInitNewRow(e) {
        // e.data.viewFl = this.$_getUsedCode.id;
        e.data.viewFl = 'Y';
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.regDt) {
          return this.$_commonlib.formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
