<!--
  PACKAGE_NAME : src/pages/euc/device/server
  FILE_NAME : index.vue.vue
  AUTHOR : jhcho
  DATE : 2024-04-19
  DESCRIPTION :
-->
<template>
  <div class="container">
    <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
      <template v-for="(item, index) in getComponent">
        <Tab :title="item.title" :key="`Tab${index}`">
          <div v-if="tabIndex === index" class="locker_setting_list sub_new_style01 sub_ui_box1">
            <div class="cusmain-table-wrap">
              <component :is="item.component" />
            </div>
          </div>
        </Tab>
      </template>
    </Tabs>
  </div>
</template>
<script>
  import Tabs from '@/components/common/tabs.vue';
  import Tab from '@/components/common/tab.vue';
  import CmList from '@/pages/euc/device/server/cmList.vue';
  import AesList from '@/pages/euc/device/server/aesList.vue';
  import SvrList from '@/pages/euc/device/server/svrList.vue';
  import SvrAesList from '@/pages/euc/device/server/svr-aesList.vue';
  import DnPatternList from '@/pages/euc/device/server/dnPatternList.vue';
  import ExceptList from '@/pages/euc/device/server/exceptList.vue';
  import IpLocationList from '@/pages/euc/device/server/ipLocationList.vue';

  export default {
    components: {
      Tabs,
      Tab,
      CmList,
      AesList,
      SvrList,
      SvrAesList,
      DnPatternList,
      ExceptList,
      IpLocationList,
    },
    props: {},
    watch: {},
    data() {
      return {
        tabIndex: 0,
        cmList: [],
        tabs: {
          selectedIndex: this.$store.getters.getTabHists[this.$router.currentRoute.path],
        },
        config: {
          stylingMode: 'outlined', //outlined, underlined, filled
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
            formData: {},
          },
        },
        tabItems: [
          {
            title: 'CM 관리',
            component: CmList,
            vender: ['AVAYA', 'CISCO'],
          },
          {
            title: 'AES 관리',
            component: AesList,
            vender: ['AVAYA'],
          },
          {
            title: 'SVR 관리',
            component: SvrList,
            vender: ['AVAYA', 'CISCO'],
          },
          {
            title: 'AES/SVR 관리',
            component: SvrAesList,
            vender: ['AVAYA'],
          },
          {
            title: 'PATTERN 관리',
            component: DnPatternList,
            vender: ['AVAYA', 'CISCO'],
          },
          {
            title: 'Except 관리',
            component: ExceptList,
            vender: ['AVAYA', 'CISCO'],
          },
          {
            title: 'IP 위치 관리',
            component: IpLocationList,
            vender: ['AVAYA', 'CISCO'],
          },
        ],
      };
    },
    computed: {
      getVender() {
        return this.$_getSystemData('vender')?.configValue || 'AVAYA';
      },
      getComponent() {
        return this.tabItems.filter(item => item.vender.includes(this.getVender));
      },
    },
    methods: {
      async tabSelectedIndex(index) {
        // cm data 변경 시 aes, svr에서 반영할 수 있도록 하기 위해 v-if 사용
        this.tabIndex = index;
      },
    },
    created() {},
    mounted() {},
    destroyed() {},
  };
</script>

<style scoped></style>
